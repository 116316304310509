import { css } from "@emotion/core"
import { colors } from "css/theme"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { If } from "src/components"
import PageLayout from "src/components/PageLayout"
import "src/queries/featured_media"
import * as util from "src/util"
import DraftLabel from "../components/DraftLabel"
import SEO from "../components/seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const wrapper = css`
  padding-top: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  iframe {
    margin-bottom: 0;
    width: 100%;

    @media (max-width: 576px) {
      margin-right: -1.5rem;
      margin-left: -1.5rem;
      height: 270px;
      width: calc(100% + 3rem);
    }
  }
  @media (max-width: 1199px) {
    flex-direction: column;
    max-width: calc(670px + 3rem);
    margin-left: auto;
    margin-right: auto;
  }
`

const wrapperInner = css`
  width: 100%;
  max-width: 670px;
  margin-left: 26px;
  margin-right: 26px;
  @media (max-width: 1199px) {
    order: -1;
    margin-left: auto;
    margin-right: auto;
  }
`

const title = css`
  font-family: var(--heading);
  font-size: 40px;
  line-height: 1.3;
  color: black;
  font-weight: bold;
  margin-bottom: 12px;
  @media (max-width: 576px) {
    font-size: 28px;
  }
`

const intro = css`
  color: #4b4b4b;
  font-size: 21px;
  line-height: 1.52;
  margin-bottom: 40px;
  @media (max-width: 576px) {
    font-size: 18px;
  }
`

// const showNotes = css`
//   margin-top: 40px;
//   margin-bottom: 40px;
//   color: black;
//   font-family: var(--heading);
//   font-size: 26px;
//   line-height: 1.46;
//   font-weight: bold;
//   @media (max-width: 576px) {
//     font-size: 22px;
//   }
// `

// const content = css`
//   strong {
//     font-size: 23px;
//     font-weight: bold;
//     margin-top: 40px;
//     display: block;
//     @media (max-width: 576px) {
//       font-size: 19px;
//     }
//   }
//   color: black;
//   font-size: 18px;
//   line-height: 1.56;
//   @media (max-width: 576px) {
//     font-size: 1rem;
//   }
// `

const personWrapper = css`
  max-width: 220px;
  width: 100%;
  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
    max-width: none;
    margin-top: 2rem;
  }
  @media (max-width: 576px) {
    display: block;
  }
`

const personImage = css`
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-bottom: 36px;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  @media (max-width: 1199px) {
    margin-right: 1rem;
    margin-bottom: 0;
    width: 100px;
    height: 100px;
  }
  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

const personRole = css`
  font-family: var(--heading);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.78px;
  text-transform: uppercase;
`

const personName = css`
  font-family: var(--heading);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.78px;
  color: ${colors.orange};
  text-transform: uppercase;
  margin-bottom: 12px;
`
const personIntro = css`
  font-size: 15px;
  line-height: 1.6;
  color: #4b4b4b;
  max-width: 180px;
  @media (max-width: 1199px) {
    max-width: none;
  }
`

const contentWrapper = css`
  width: 100%;
  max-width: calc(670px + 3rem);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const ArticleTemplate = ({ data }) => {
  const post = data.wordpressWpUnfiltered
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = util.compileArticleSEOFields(post, siteUrl)
  // const postContent = util.replaceImageBase(post.content)
  const videoCode = post.acf.video.split("?v=")?.[1]
  const videoLink = `https://www.youtube-nocookie.com/embed/${videoCode}`
  const [hostImageUrl, hostAltText] = util.computeAcfImageAttr(
    post.acf.host_image
  )
  const [guestImageUrl, guestAltText] = util.computeAcfImageAttr(
    post.acf.guest_image
  )
  return (
    <PageLayout options={{ headingBackground: false, light: true }}>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        ogType="article"
        extra={otherMetaTags}
        description={post.excerpt}
      />
      <div css={wrapper}>
        <div css={personWrapper}>
          <div>
            <If test={hostImageUrl}>
              <div css={personImage}>
                <Img fluid={hostImageUrl} alt={hostAltText} />
              </div>
            </If>
          </div>
          <div>
            <div css={personRole}>The Host</div>
            <div css={personName}>{post.acf.host_name}</div>
            <div css={personIntro}>{post.acf.host_intro}</div>
          </div>
        </div>
        <div css={wrapperInner}>
          <DraftLabel status={post.status} />
          <h1 css={title}>{post.title}</h1>
          <div css={intro}>{post.acf.intro}</div>
          <iframe
            width="664px"
            height="391px"
            src={videoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="1"
            title={post.title}
          ></iframe>
        </div>
        <div css={personWrapper}>
          <div>
            <If test={guestImageUrl}>
              <div css={personImage}>
                <Img fluid={guestImageUrl} alt={guestAltText} />
              </div>
            </If>
          </div>
          <div>
            <div css={personRole}>The Guest</div>
            <div css={personName}>{post.acf.guest_name}</div>
            <div css={personIntro}>{post.acf.guest_intro}</div>
          </div>
        </div>
      </div>
      <div css={contentWrapper}>
        {/* <div css={showNotes}>Show notes</div>
        <div dangerouslySetInnerHTML={{ __html: postContent }} css={content} /> */}
      </div>
    </PageLayout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query UnfilteredArticleTemplate($id: String!) {
    wordpressWpUnfiltered(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      modified
      status
      acf {
        intro
        video
        guest_name
        guest_intro
        guest_image {
          large: localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        host_name
        host_intro
        host_image {
          large: localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
  }
`
